
import React from "react";
import moment from "moment";
import { toCurrency } from "../tools/currency";
import {fullServerAddress} from "../tools/config"




export const gridOrderStatus = (props) => {
    let xy = StatusBg[props.Status];
    
    return (<span
        style={{ background: xy.StatusBg }}
        className="text-white py-1 px-2 capitalize rounded text-md"
    >
        {xy.Status}
    </span>);
};

export const gridOrderType=(props)=>{
    let xy = typeBg[props.OrderType];
    return (<span
        style={{ background: xy.StatusBg }}
        className="text-white py-1 px-2 capitalize rounded text-md"
    >
        {xy.Status}
    </span>);
}


export const gridOrderPaid=(props)=>{
    let xy = PaidBg[props.Payment];
    return (<span
        style={{ background: xy.StatusBg }}
        className="text-white py-1 px-2 capitalize rounded text-md"
    >
        {xy.Status}
    </span>);
}

export const gridPrice=(props)=>{    
    return (<span>
        {toCurrency(props.total)}
    </span>);
}

export const gridItemPrice=(props)=>{    
    return (<span>
        {toCurrency(props.Price)}
    </span>);
}
export const gridItemTotal=(props)=>{    
    return (<span>
        {toCurrency(props.Price*props.Quantity)}
    </span>);
}
export const ordersGrid = [
    {
        field: 'OrderDate',
        headerText: 'Order Date',
        textAlign: 'Left',
        width: '150',
        allowEditing : false,
    },
    {
        field: 'OrderType',
        headerText: 'Order Type',
        textAlign: 'Center',
        width: '100',
        template: gridOrderType,
    },
    {
        field: 'Payment',
        headerText: 'Payment type',        
        textAlign: 'Center',
        width: '100',
        template: gridOrderPaid,
        allowEditing : false,
    },
    {
        headerText: 'Status',
        field: 'Status',
        textAlign: 'Center',
        width: '100',
        template: gridOrderStatus,
        allowEditing : false,
    },
    {
        field: 'UserName',
        headerText: 'Staff Name',
        textAlign: 'Center',
        width: '100',
        allowEditing : false,
    },

    {
        field: 'TableName',
        headerText: 'Table Name',
        textAlign: 'Center',
        width: '50',
        allowEditing : false,
    },{
        field: 'total',
        headerText: 'Total',
        textAlign: 'right',
        width: '50',
        template: gridPrice,
        allowEditing : false,
    },
    {
        field: 'IID',
        width: '0',
        IsPrimaryKey: true,
        Visible: false,
        allowEditing : false,
    },
];


export const orderItemsGrid = [
    {
        field: 'EntityName',
        headerText: 'Main Item',
        textAlign: 'Left',
        width: '100',
        allowEditing : false,
    },{
        field: 'OrderItemText',
        headerText: 'Name',
        textAlign: 'Left',
        width: '150',
        allowEditing : false,
    },{
        field: 'Quantity',
        headerText: 'Quantity',
        textAlign: 'Left',
        width: '50',
        allowEditing : false,
    },{
        field: 'Price',
        headerText: 'Price',
        textAlign: 'Left',
        width: '50',
        template: gridItemPrice,
        allowEditing : false,
    },{
        field: 'total',
        headerText: 'Total',
        textAlign: 'Left',
        width: '50',
        template: gridItemTotal,
        allowEditing : false,
    },
    {
        field: 'IID',
        width: '0',
        IsPrimaryKey: true,
        Visible: false,
        allowEditing : false,
    },
]

export const ordersData = (fn)=>{

    fetch(fullServerAddress + '/api/orders/getOrders', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => {
            let x = data.map((item, index) => {
                return {
                    OrderDate: moment(item.OrderDate).utc().format('YYYY/MM/DD HH:mm'),
                    OrderType: item.OrderType,
                    Payment: item.Payment,
                    Status: item.Status,
                    UserName: item.UserName,
                    TableName: item.TableName,
                    IID:item.IID,
                    WVat:item.WVat,
                    NVat:item.NVat,
                    vat:item.vat,
                    total:item.Total,
                    sub:item.sub,
                    
                };
            });




          //console.log(x)
          fn(x);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });

}

/* */


export const StatusBg = {
    0: {
        Status: 'New',
        StatusBg: '#FEC90F',
    },
    1: {
        Status: 'Open',
        StatusBg: '#8BE78B',
    },
    2: {
        Status: 'Done',
        StatusBg: '#FF5C8E',
    },
    3: {
        Status: 'Paid',
        StatusBg: '#03C9D7',
    },
    4: {
        Status: 'Archived',
        StatusBg: '#999999',
    }
};


export const typeBg = {
    0: {
        Status: 'Unknown',
        StatusBg: '#CCCCCC',
    },
    1: {
        Status: 'In-House',
        StatusBg: '#0C2D48',
    },
    256: {
        Status: 'Takeaway',
        StatusBg: '#000C66',
    }
};

export const PaidBg = {
    0: {
        Status: 'UnPaid',
        StatusBg: '#cccccc',
    }, 
    1: {
        Status: 'Cash',
        StatusBg: '#00cc00',
    }, 
    2: {
        Status: 'CreditCard',
        StatusBg: '#122620',
    },
    3:{
        Status: 'Card',
        StatusBg: '#B68D20',
    },
    4:{
        Status: 'Cheque',
        StatusBg: '#8BE78B',
    }
};




